import * as React from 'react'
import { CenteredCard } from '../components/shared/card'
import Layout from '../layout'
import { BigHeader } from '../components/shared/header'
import { DiamondSection } from '../components/shared/section'
import { Colors } from '../components/core/colors'
import { AnimationTypes } from '../components/shared/section/animations'
import { graphql } from 'gatsby'


export default class Home extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout location={'/'}>
        <BigHeader
          srcSet={this.props.data.headerBackground.childImageSharp.fluid.srcSet}
          title={'Ihr starker Partner am Bau aus der Region Ostbayern'}
          buttonLabel={'Mehr erfahren'}
          href={'/services'}/>

        <CenteredCard centered label={'Für Ihr Zuhause, Gewerbe und Landwirtschaft'}
                      headline={'Beraten · Bauen · Begleiten'}/>

        <DiamondSection reveal={true}
                        animate={AnimationTypes.FromBottom}
                        diamondBackground={this.props.data.buildingImage.childImageSharp.fluid.srcSet}
                        headline={'Amann Betonbau - Massivbau'}
                        text={'Die Firma Amann Betonbau wurde im Jahr 1975 von Ingenieur Adalbert Amann in Mintraching / Tiefbrunn gegründet. Betonbauten mit Stahlschalung für monolithische Rundbehälter und Großflächenschalung für den Industriebau waren die Basis für eine erfolgreiche Zukunft. Wir bauen für Industrie, Gewerbe, Landwirtschaft, private und öffentliche Auftraggeber.'}
                        footnote={'Auf Wunsch können wir die zugehörigen Erd- und Leitungsarbeiten durch Partnerfirmen mit ausführen.'}/>

        <DiamondSection reveal={true}
                        animate={AnimationTypes.FromLeft}
                        background={Colors.white} reversed={true}
                        diamondBackground={this.props.data.concreteImage.childImageSharp.fluid.srcSet}
                        text={'Unsere Bauingenieure und Facharbeiter gewährleisten eine qualitativ hochwertige Bauausführung. Durch die enge Zusammenarbeit mit Bauherrn und Architekten finden wir praxisorientierte und durchdachte Lösungen für die Umsetzung des Bauwerks. Bereits in der Angebotsphase beraten wir unsere Kunden hinsichtlich optimierter und wirtschaftlicher Konzepte für ihr Projekt. \n' +
                        'Für technische Fragen und Planungen steht unser Ingenieurbüro zur Verfügung. Dazu gehören auch Entwurfs-, Eingabe- und Werkplanung.\n' +
                        'Unser Betrieb ist in der Handwerksrolle eingetragen.'}
                        headline={'Bauplanung und Baubegleitung'}
                        subline={'Wir unterstützen Sie bei Ihrer individuellen Lösung'}/>

        <DiamondSection reveal={true}
                        animate={AnimationTypes.FromBottom}
                        diamondBackground={this.props.data.waterImage.childImageSharp.fluid.srcSet}
                        headline={'Fachbetrieb nach Wasserhaushaltsgesetzt'}
                        text={'Wir sind als Fachbetrieb nach Wasserhaushaltsgesetz (WHG) zugelassen. Damit dürfen wir Bauwerke zum Umgang mit wassergefährdenden Flüssigkeiten errichten.'}
                        logo={this.props.data.tuev.childImageSharp.fluid.srcSet}/>

      </Layout>
    )
  }
}

export const HomeQuery = graphql`{
    headerBackground: file(name: {eq: "header"}) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    concreteImage: file(name: {eq: "concrete"}) {
        childImageSharp {
            fluid(maxWidth: 400) {
                srcSet
            }
        }
    }
    buildingImage: file(name: {eq: "betonhaus"}) {
        childImageSharp {
            fluid(maxWidth: 400) {
                srcSet
            }
        }
    }
    waterImage: file(name: {eq: "water"}) {
        childImageSharp {
            fluid(maxWidth: 400) {
                srcSet
            }
        }
    }
    tuev: file(name: {eq: "tuev"}) {
        childImageSharp {
            fluid(maxWidth: 400) {
                srcSet
            }
        }
    }
}
`
